/*Consider taking out the background image and defining it on Grid elements directly.*/
html {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('./assets/MountainBackgroundBlur.png'); */
  background-image: url('./assets/uplift_background.png');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  top: 0;
  background-position-x: 80%;
  height: 100vh;
}
